<!-- src/components/GeoRestriction.vue -->
<template>
  <div class="flex items-center justify-center p-8">
    <div class="max-w-md w-full space-y-8 text-center">
      <!-- World Icon -->
      <div class="mx-auto w-24 h-24 rounded-full bg-yellow-600/10 flex items-center justify-center">
        <Globe class="w-12 h-12 text-yellow-400"/>
      </div>

      <!-- Error Message -->
      <div class="space-y-4">
        <h1 class="text-xl font-display font-bold text-slate-100">
          We're not available in your country (yet!)
        </h1>
        <p class="text-slate-400">
          We're currently available in EU and US and UK.
        </p>
      </div>

      <!-- Email Form -->
      <form v-if="!submitted" @submit.prevent="submitEmail" class="mt-8 space-y-6">
        <div class="space-y-4">
          <div>
            <label for="email" class="sr-only">Email</label>
            <input
                id="email"
                v-model="email"
                type="email"
                required
                class="w-full px-4 py-2 bg-slate-800/50 border border-slate-600/50
                    text-slate-300 placeholder-slate-500 rounded-lg
                    focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20"
                placeholder="Enter your email to get notified"
            />
          </div>

          <!-- Consent Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
                          group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="consent"
                    class="opacity-0 absolute cursor-pointer"
                    required
                />
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !consent }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <div class="flex-1 text-sm text-slate-300 text-left">
              I agree to the
              <router-link to="/terms" class="text-blue-400 hover:text-blue-300">Terms and Conditions</router-link>,
              <router-link to="/privacy" class="text-blue-400 hover:text-blue-300">Privacy Policy</router-link>, and
              <router-link to="/newsletter-terms" class="text-blue-400 hover:text-blue-300">Newsletter Terms</router-link>.
            </div>
          </label>
        </div>

        <!-- Error Message -->
        <ErrorBox/>

        <button
            v-if="!submitted"
            type="submit"
            :disabled="sessionStore.loading || !consent"
            class="w-full px-6 py-3 bg-blue-600 hover:bg-blue-500 disabled:bg-blue-600/50
                text-white rounded-lg font-medium transition-all duration-200
                flex items-center justify-center space-x-2"
        >
          <div v-if="sessionStore.loading" class="flex items-center space-x-2">
            <span>Submitting</span>
            <LoadingDots />
          </div>
          <span v-else>Notify me</span>
        </button>
      </form>

      <!-- Success Message -->
      <div v-if="submitted" class="p-4 bg-emerald-600/10 border border-emerald-500/20 rounded-lg">
        <p class="text-emerald-400">Thanks! We'll notify you when we launch in your country.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Globe } from 'lucide-vue-next'
import LoadingDots from './LoadingDots.vue'
import {useSessionStore} from "@/store/session";
import ErrorBox from "@/components/ErrorBox.vue";

const email = ref('')
const consent = ref(false)
const submitted = ref(false)

const sessionStore = useSessionStore()

async function submitEmail() {
  await sessionStore.notifyWhenAvailable(email.value)
  if(!sessionStore.error) {
    submitted.value = true
  }
}
</script>