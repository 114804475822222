<template>
  <!-- Template remains the same as previous version -->
  <div class="p-6">
    <div class="max-w-3xl mx-auto">
      <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 mb-8">
        Help & Contact
      </h1>

      <div class="space-y-8">
        <div v-for="(section, sectionIndex) in faqSections"
             :key="sectionIndex"
             class="space-y-4">
          <div class="flex items-center space-x-3">
            <component :is="section.icon" class="h-5 w-5 text-blue-400"/>
            <h2 class="text-xl font-display font-semibold text-slate-100">{{ section.title }}</h2>
          </div>

          <div class="space-y-2">
            <div v-for="(qa, qaIndex) in section.items"
                 :key="qaIndex">
              <button
                  @click="toggleQuestion(sectionIndex, qaIndex)"
                  class="w-full px-4 py-3 text-left
                         hover:bg-slate-800/50 rounded-lg
                         transition-colors duration-200
                         flex items-center justify-between group"
              >
                <span class="text-slate-200 font-medium group-hover:text-slate-100">{{ qa.q }}</span>
                <ChevronDown
                    class="h-4 w-4 text-slate-400 transform transition-transform duration-200 flex-shrink-0 ml-4"
                    :class="{ 'rotate-180': isQuestionOpen(sectionIndex, qaIndex) }"
                />
              </button>

              <div
                  v-show="isQuestionOpen(sectionIndex, qaIndex)"
                  class="px-4 py-3 text-slate-300"
              >
                <p class="text-slate-300" v-html="qa.a"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 p-6 bg-blue-600/10 border border-blue-500/20 rounded-lg">
        <h2 class="text-xl font-display font-semibold text-blue-400 mb-2">
          Still need help?
        </h2>
        <div class="space-y-4">
          <p class="text-slate-300">
            Our support team is ready to assist you:
          </p>
          <div class="flex flex-col space-y-3">
            <a href="mailto:support@kidstelltales.com"
               class="inline-flex items-center text-blue-400 hover:text-blue-300">
              <Mail class="h-4 w-4 mr-2" />
              <span>support@kidstelltales.com</span>
            </a>
            <a href="https://discord.gg/h7Xrhqsbhv"
               target="_blank"
               rel="noopener noreferrer"
               class="inline-flex items-center text-blue-400 hover:text-blue-300">
              <MessageSquare class="h-4 w-4 mr-2" />
              <span>Join our Discord community</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted, computed} from 'vue'
import { useRoute } from 'vue-router'
import {
  Mail,
  ChevronDown,
  BookOpen,
  CreditCard,
  Shield,
  MessageSquare,
  Users,
  Brain,
} from 'lucide-vue-next'
import { useMetaTags } from "@/components/useMetaTags"
import {useCurrentUser} from "vuefire";

const route = useRoute()
const openQuestions = ref(new Map<string, boolean>())

const faqSections = [
  {
    title: "Getting Started",
    icon: BookOpen,
    items: [
      {
        q: "How do I start creating stories?",
        a: "Click 'New Story' in the navigation bar, enter a story prompt, select the age group, and choose your desired story length. The AI will generate an interactive story based on your input.",
      },
      {
        q: "I'm seeing error messages",
        a: "Please try refreshing the page or the action again. If issues persist, contact support for assistance.",
      },
      {
        q: "The story generated by Kids Tell Tales wasn't too good",
        a: "We're really sorry to hear that! Please note that AI technology, while advanced, can sometimes generate stories that don't meet expectations. To get better results, try to:<br><br><ul class='list-disc pl-6 space-y-2'><li>Be specific in your story prompt - instead of 'a story about a dog' try 'a story about a lonely puppy who learns to make friends at the dog park'</li><li>If you don't like the direction of the story, you can always start over with a new prompt</li><li>Choose age-appropriate settings - stories for 3-4 year olds will be simpler than those for 8+ year olds</li></ul><br>If you consistently find the quality unsatisfactory, please contact our support team - your feedback helps us improve!"
      },
      {
        q: "Which countries is the service available in?",
        a: "We are currently available in the European Union, United States and United Kingdom.",
      }
    ]
  },
  {
    title: "Usage & Billing",
    icon: CreditCard,
    items: [
      {
        q: "How much does it cost?",
        a: "First few stories are free. Then our plan is $7.99, which gives you 450 AI interactions valid for 30 days. Each interaction allows you to create a new story or progress through existing story by making choices. The plan does not auto-renew - you'll need to purchase it again after expiration.",
      },
      {
        q: "How do I check my plan details?",
        a: "Go to the Account section (top menu), where you'll see when your current plan ends and how many AI interactions remain until the period end.",
      },
      {
        q: "What counts as an AI interaction?",
        a: "Each time you start a new story or make a choice in an existing story counts as one interaction. Note that receiving a 'Content Not Suitable for Children' message also counts as one interaction.",
      },
      {
        q: "What can I create with 450 interactions?",
        a: "Depending on the story length you choose, you can create approximately:<br><ul class='list-disc pl-6 pt-2 space-y-1'><li>90 short stories</li><li>OR 64 medium stories</li><li>OR 50 long stories</li></ul>You can mix and match - interactions are used flexibly across any story length."
      }
    ]
  },
  {
    title: "Story Features",
    icon: MessageSquare,
    items: [
      {
        q: "Can I come back to the story later?",
        a: "Yes! All your unfinished stories are automatically saved and can be found in the 'Your Stories' section. You can continue any story from where you left off.",
      },
      {
        q: "Can I save and share stories?",
        a: "Yes! Stories are automatically saved to your account. Once complete, you can make them public and share them with others using the share button. You can also make a shared story private again at any time from the story page.",
      }
    ]
  },
  {
    title: "Account Management",
    icon: Users,
    items: [
      {
        q: "How do I reset my password?",
        a: "Click 'Sign In', then 'Forgot password?' and follow the instructions sent to your email.",
      },
      {
        q: "Why do I need to verify my email?",
        a: "Email verification helps us secure your account and ensure we can contact you about important account updates.",
      }
    ]
  },
  {
    title: "Safety & Content",
    icon: Shield,
    items: [
      {
        q: "How do you ensure content safety?",
        a: "Our AI is specifically adjusted to create child-friendly content. All story prompts are screened for appropriateness, and content is tailored to the selected age group.",
      },
      {
        q: "I'm seeing 'Content Not Suitable for Children' message",
        a: "We treat child safety seriously and prohibit frightening and disturbing topics not appropriate for children. Please modify your prompt or contact us if you think the prompt you've provided is safe. Note that this check counts as one AI interaction from your plan.",
      },
      {
        q: 'Can I report inappropriate content?',
        a: 'Yes, please contact our support team immediately if you encounter any inappropriate content. We take content safety very seriously.'
      }
    ]
  }
]

// Rest of the script remains the same
function toggleQuestion(sectionIndex: number, qaIndex: number) {
  const key = `${sectionIndex}-${qaIndex}`
  openQuestions.value.set(key, !isQuestionOpen(sectionIndex, qaIndex))
}

function isQuestionOpen(sectionIndex: number, qaIndex: number): boolean {
  return openQuestions.value.get(`${sectionIndex}-${qaIndex}`) || false
}

onMounted(() => {
  useMetaTags({
    title: 'Help & Contact',
    path: route.path,
  })

})
</script>